import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FeaturedNews from "./FeaturedNews";
import MultiMediaComponents from "./MultimediaComponents";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography } from "@mui/material";
import { useLoader } from "../context/LoaderContext"; 

const useStyles = makeStyles({
  gridItem: {
    backgroundColor: "#1a191e",
    paddingTop: "60px",
    marginLeft:10,
  

  },
  footer: {
    backgroundColor: "#1a191e",
  },
  cardElement: {
    backgroundColor: "#1a191e !important",
  },
  media: {
    // width: "100px",
  },
});

const RigtSidebar = () => {
  const classes = useStyles();
  
  return (
    
    <Box sx={{ flexGrow: 1 }} style={{ paddingTop: 60, backgroundColor: "#1a191e", marginLeft:"25%"}} className="mobVerVij">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <FeaturedNews />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <MultiMediaComponents />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Grid item xs={3}>
          <Card className={classes.cardElement}>
            <a href="http://www.asu.unsa.ba"  target="_blank">
              <img
                className={classes.media}
                src="/assets/Logo-ASU.png"
                alt="Image Alt Text"
                height= "88px !important"
                width="300px !important"
              />
            </a>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.cardElement}>
            <a href="http://www.mtm.ba/"  target="_blank">
              <img
                className={classes.media}
                src="/assets/logo-dark@2x.png"
                alt="Image Alt Text"
                height= "88px"
              />
            </a>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.cardElement}>
            <a href="https://worldvoiceday.org/"  target="_blank">
              <img
                className={classes.media}
                src="/assets/wvc.png"
                alt="Image Alt Text"
                height= "88px"
              />
            </a>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.cardElement}>
            <a href="https://www.rslawards.com"  target="_blank"> 
              <img
                className={classes.media}
                src="/assets/RSL-Default.svg"
                alt="Image Alt Text"
                // height= "88px"
              />
            </a>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.cardElement}>
            <a href="https://open.spotify.com/artist/6Rzshn9onqSCEuGCUIZ6WP"  target="_blank"> 
              <img
                className={classes.media}
                src="/assets/Spotify_icon.png"
                alt="Image Alt Text"
                height= "88px"
              />
            </a>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RigtSidebar;
